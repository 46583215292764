/*
 * IMPORT
 */
import React, {Component} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";


/*
 * COMPONENT
 */
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import PageHelmet from "../component/common/Helmet";
import Brand from "../elements/Brand";


/*
 * Class
 */
class Customer extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Customer'/>

                {/* Start Header Area  */}
                <HeaderThree homeLink="/" logo="symbol-light" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
                        data-black-overlay="7">
                    <div className="container" style={{'width': '100%'}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Our Customers</h2>
                                    <p>Trusted by many</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rn-page-title-area pt--120 pb--190">
                    <Brand/>
                </div>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>


            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default Customer;
