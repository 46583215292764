/*
 * IMPORTS
 */
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

/*
 * Class Component
 */
class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} - Manufacturer of Wires and Cables</title>
                    <meta name="description" content="BMI - Manufacturer of Wires and Cables" />
                </Helmet>
            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default PageHelmet;
