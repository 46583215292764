/*
 * IMPORT
 */
import React, {Component} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";


/*
 * COMPONENT
 */
import TabOne from "./tab/TabOne";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import TeamOne from "../blocks/team/TeamOne";
import PageHelmet from "../component/common/Helmet";
import TabEnamelled from "./tab/TabEnamelled";

/*
 * Const Assignment
 */
const _List = [
    {
        'image': '/assets/images/Elastomeric/1.webp',
        'heading': 'LT / MV Elastomeric (Rubber) Power & Control Cables',
        'type': 'ELASTOMERIC (RUBBER) CABLE',
        'background':'/assets/images/productType/elastomeric_bg.png',
        'slug': 'ltmv-elastomeric-rubber-power-cables',
        'infoDescription': "Rubber cables are electrical cables that are covered in a rubber insulation material. This type of cable is often used in harsh environments or applications where the cable needs to be flexible, durable, and resistant to temperature, oil, and other environmental factors. Rubber cables can be used in various industries such as construction, mining, automotive, and marine. The rubber insulation provides protection against abrasion, water, and chemicals, making them suitable for use in outdoor and underground applications.When selecting elastomeric rubber cables, it is important to consider the specific requirements of the application, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable's performance",
        'description': 'EPR insulated cables confirming / as per IS:9968 Part-1 & II, IEC: 60502, BS:6899 or customer requirement.',
        'size': ['Single Core cables up to 630 Sq.mm', 'Multi Core cables up to 400 Sq.mm', 'Control Cables upto 61 cores.'],
        'designProcess': [
            ['Conductor: ', 'Multi Stranded / Solid Annealed Tinned / Bare copper conductor.'],
            ['Insulation: ', 'EPR/ EVA/ Butyl/ Silicon.'],
            ['Inner Sheath: ', 'PCP/ CSP/ NBR-PVC / EVA/ Butyl/ Silicon / LSZH.'],
            ['Reinforcement: ', 'Nylon / Cotton Yarn Braided.'],
            ['Outer Sheath: ', 'PCP/ CSP/ NBR-PVC / EVA/ Butyl/ Silicon /LSZH.']
        ],
        'features': [
            ['Flexibility: ', ' Elastomeric rubber cables are highly flexible, making them ideal for use in applications that require constant movement or bending.'],
            ['Durability: ', 'Elastomeric rubber cables are designed to withstand harsh environmental conditions, such as extreme temperatures, chemicals, and abrasion'],
            ['Resistance to environmental factors: ', 'Elastomeric rubber cables are resistant to a range of environmental factors, including moisture, oils, and UV radiation.'],
            ['Wide range of applications: ', 'Elastomeric rubber cables are commonly used in a variety of applications, including in industrial machinery, robotics, and portable power tools.']
        ],
        'crossSection': '/assets/images/Elastomeric/cross_section/1.png',
        'abbreviations': [
            ['EPR: ', 'Ethylene Propylene Rubber'],
            ['Silicon: ', 'Silicon Rubber'],
            ['PCP: ', 'Polychloroprene'],
            ['CSP: ', 'Chlorosulphonated Polyethylene'],
            ['EVA', 'Ethylene Vinyl Acetate'],
            ['NBR-PVC', 'Acryionitriie-Butadiene Rubber-Poiyvinyl Chloride']
        ]
    },
    {
        'image': '/assets/images/Elastomeric/2.webp',
        'heading': 'Elastomeric (Rubber) Mining Cables',
        'type': 'ELASTOMERIC (RUBBER) CABLE',
        'background':'/assets/images/productType/elastomeric_bg.png',
        'slug': 'mining-cables',
        'infoDescription': 'Mining cables are heavy-duty electrical cables designed for use in harsh mining environments. These cables are specifically designed to withstand extreme conditions such as high temperatures, dust, vibration, impact, and exposure to chemicals and minerals. Mining cables are often made of durable materials such as synthetic rubber to ensure long-term reliability and protection against damage. \n' +
            '\n' +
            'These are flexible cables which are used in Under Ground and Opencast Mines for Drill trailing application, LHD/ SDL/ UDM machines, HEMM, Oil Rig, Off¬shore.\n' +
            ' Mining cables must meet strict safety and performance standards to ensure the safety of miners and the longevity of the equipment. ',
        'description': 'ERP insulated cables confirming / as per IS: 14494/2019, IEC:60092 or customer requirements. Drill Trailing cable Type FTD-3 SDL/LHD/UDM Machine cable Type FT-7',
        'size': ['Single Core cables up to 300 Sq. mm, Screened/Unscreened.', 'Multi Core cables up to 185 Sq. mm. Screened/Unscreened'],
        'designProcess': [
            ['Conductor: ', 'Multi Stranded Annealed Tinned copper conductor.'],
            ['Insulation: ', 'EPR'],
            ['Inner Sheath: ', 'PCP/CSP'],
            ['Reinforcement: ', 'Nylon / Cotton Yarn Braided'],
            ['Screening / Braiding: ', 'ATC combined with Nylon Braiding individually and overall as per application / specification'],
            ['Outer Sheath: ', 'PCP / CSP']
        ],
        'features': [
            ['Durability: ', 'Mining cables are designed to withstand harsh environmental conditions, such as extreme temperatures, moisture, and mechanical stress.'],
            ['Chemical resistance: ', 'Mining cables are often designed to be resistant to chemicals commonly found in mining environments, such as oils and acids.'],
            ['Flame resistance: ', 'Mining cables are often designed to be flame-resistant to prevent fires in the event of a spark or electrical fault.']
        ],
        'crossSection': '/assets/images/Elastomeric/cross_section/2.png',
        'abbreviations': [
            ['EPR: ', 'Ethylene Propylene Rubber'],
            ['PCP: ', 'Polychloroprene'],
            ['CSP: ', 'Chlorosulphonated Polyethylene']
        ]
    },
    {
        'image': '/assets/images/Elastomeric/3.webp',
        'heading': 'Composite/Hybrid Cables',
        'type': 'ELASTOMERIC (RUBBER) CABLE',
        'background':'/assets/images/productType/elastomeric_bg.png',
        'slug': 'composite-cables-up-to-11-kv',
        'infoDescription': "Composite cables are electrical cables that combine multiple types of cables, such as power cables, control cables, and data cables, into a single, integrated cable. These cables are designed to simplify installation and reduce the amount of cabling required for complex systems by combining multiple functions into a single cable.When selecting composite cables, it is important to consider the specific requirements of the system, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable's performance.",
        'description': 'EPR insulated cables confirming / as per IS:14494/2019, IEC:60092 or customer requirement.',
        'size': ['Multi Core cables up to 240 Sq.mm with split earth core.'],
        'designProcess': [
            ['Conductor: ', 'Multi Stranded / Annealed Tinned / Bare copper conductor'],
            ['Conductor Screening: ', 'Semi-Conducting Compound / Semi Conducting Tape.'],
            ['Insulation: ', 'EPR/HEPR'],
            ['Insulation Screening: ', 'Semi-Conducting Compound'],
            ['Inner Sheath: ', 'PCP/ CSP /NBR-PVC'],
            ['Reinforcement: ', 'Nylon / Cotton Yarn Braided'],
            ['Screening/Braiding: ', 'ATC combined with Nylon Braiding individually and overall as per application wherever required.'],
            ['Outer Sheath: ', 'PCP/ CSP/NBR-PVC']
        ],
        'features': [
            ['Multiple functions: ', 'Composite cables can combine power, control, and data cables into a single cable, reducing the number of cables needed in a system and simplifying installation.'],
            ['Customizable: ', 'Composite cables can be customized to meet specific application requirements, such as different types of power, control, and data cables.'],
            ['Durability: ', 'Composite cables are often designed to withstand harsh environmental conditions, including extreme temperatures, moisture, and mechanical stress.'],
            ['Cost-effective:','Composite cables can be a cost-effective solution for complex systems, as they reduce the amount of cabling required and simplify installation.']
        ],
        'crossSection': '/assets/images/Elastomeric/cross_section/3.png',
        'abbreviations': [
            ['EPR: ', 'Ethylene Propylene Rubber'],
            ['PCP: ', 'Polychloroprene'],
            ['CSP: ', 'Chlorosulphonated Polyethylene']
        ]
    },
    {
        'image': '/assets/images/Elastomeric/4.webp',
        'heading': ' Silicon Rubber High Temprature Cables',
        'type': 'ELASTOMERIC (RUBBER) CABLE',
        'background':'/assets/images/productType/elastomeric_bg.png',
        'slug': 'silicon-rubber-high-temperature-cables-power',
        'infoDescription': "Silicon rubber high temperature cables are electrical cables made with a silicon rubber insulation material that is able to withstand high temperatures. These cables are commonly used in applications that require heat resistance, such as in the automotive industry, electrical equipment, and heating systems. Silicon rubber high temperature cables are flexible, durable, and resistant to oil, and chemicals, making them suitable for use in harsh environments. The silicon rubber insulation provides excellent heat resistance, making these cables ideal for use in applications where temperatures may exceed the limit of traditional insulation materials. These cables must meet strict safety and performance standards to ensure their reliability in high temperature environments.When selecting silicone rubber high-temperature cables, it is important to consider the specific requirements of the application, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable's performance",
        'description': 'Silicon Rubber insulated Power & Control cables confirming / as per to IS:9968 Part-I & II, BS:6360/BS:6195/IEC:245-1 and Customer requirement.',
        'size': ['Single Core Cables up to 400 Sq.mm', 'Multi Core cables up to 300 Sq.mm', 'Voltage Grade: 1.1/ 3.3/ 6.6/13.8KV'],
        'designProcess': [
            ['Conductor: ', 'Multi Stranded Annealed Bare /Tinned copper conductor.'],
            ['Insulation: ', 'Silicon Rubber'],
            ['Inner Sheath: ', 'Silicon Rubber if applicable.'],
            ['Outer Sheath: ', 'Silicon Rubber'],
            ['Braiding: ', 'Fibre Glass / Synthetic Yarn Braided & Varnished as per customer requirement.']
        ],
        'features': [
            ['Temperature resistance: ', 'Silicone rubber high-temperature cables are designed to withstand extreme temperatures, typically ranging from -50°C to +180°C, and can even withstand temperatures up to +250°C for short periods.'],
            ['Flexibility: ', 'Silicone rubber high-temperature cables are highly flexible and can be used in applications where bending is required.'],
            ['Chemical resistance: ', 'Silicone rubber high-temperature cables are resistant to a wide range of chemicals, making them suitable for use in harsh environments where exposure to chemicals is common.'],
            ['Electrical properties: ', 'Silicone rubber high-temperature cables have excellent electrical properties, including good insulation resistance and low dielectric loss.']
        ],
        'crossSection': '/assets/images/Elastomeric/cross_section/4.png',
        'abbreviations': [['Silicon: ', 'Silicon Rubber']]
    },
    {
        'image': '/assets/images/Elastomeric/5.webp',
        'heading': 'Welding Cables',
        'type': 'ELASTOMERIC (RUBBER) CABLE',
        'background':'/assets/images/productType/elastomeric_bg.png',
        'slug': 'welding-cables',
        'features': [
            ['Flexibility: ', 'Welding cables are highly flexible, allowing them to be easily maneuvered and positioned during the welding process.'],
            ['Temperature resistance: ', 'Welding cables are designed to withstand high temperatures, typically ranging from -40°C to +90°C, and can even withstand temperatures up to +105°C for short periods.'],
            ['Electrical properties: ', 'Welding cables have low resistance and are able to handle high currents, typically ranging from 50 to 600 amps.'],
            ['Durability: ', 'Welding cables are designed to withstand physical stresses, such as abrasion and impact, that are common in welding environments.']
        ],
        'infoDescription': 'Welding cables are heavy-duty electrical cables used in welding applications. They are designed to withstand high temperatures, abrasion, and exposure to oils and chemicals commonly found in welding environments. Welding cables are used to supply electrical power to welding equipment and machines and must be able to handle high levels of electrical current. They are typically made of a flexible and durable material, such as rubber, to ensure that they are able to withstand the harsh conditions of welding environments. Welding cables come in different sizes and specifications to meet the specific needs of different welding applications, including portable welding machines and stationary welding equipment. It is important to use the correct type of welding cable for your application to ensure that the cable is able to provide reliable performance and to minimize the risk of damage or failure.\n' +
            '\n' +
            'When selecting welding cables, it is important to consider the specific requirements of the welding application, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable\'s performance.',
        'description': 'Insulation / Covering HOFR Rubber (Heat Resistant Oil Resistant Fire Retardant) as per IS:9857-1990',
        'size': ['In Copper: up to 95 Sq.mm', 'In Aluminium : up to 120 Sq.mm Or as per customer requirement.'],
        'designProcess': [['Conductor: ', 'Multi Stranded Annealed Bare /Tinned copper conductor (Class-6 of IS:8130)'], ['Insulation / Covering: ', 'HOFR (Heat Resistant Oil Resistant Fire Retardant) Rubber.']],
        'crossSection': '/assets/images/Elastomeric/cross_section/5.png',
        'abbreviations': [['HOFR: ', 'Heat Resistant Oil Resistant Fire Retardant Rubber']]
    },
    {
        'image': '/assets/images/LT-MV/1.webp',
        'heading': 'LT/MV PVC/XLPE POWER CABLES ',
        'type': 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        'background':'/assets/images/productType/power_bg.png',
        'slug': 'lt-power-cables-up-to-11-kv',
        'infoDescription': 'Low tension power cables, also known as low voltage (LV) power cables, are electrical cables that are designed to carry low voltage electrical power. Low tension refers to the low voltage level of the electrical power being transmitted, typically less than 1000 volts AC. These cables are used in a wide range of applications, including residential and commercial buildings, industrial facilities, and renewable energy systems. Low tension power cables are typically made of copper or aluminum conductors and have insulation made of materials such as PVC/XLPE to provide protection against electrical and environmental factors. Low tension power cables must meet specific performance and safety standards to ensure their safe and reliable operation in the intended application.\n' +
            '\n' +
            '\n' +
            'When selecting low tension power cables, it is important to consider the specific requirements of the application, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable\'s performance',
        'description': 'PVC/XLPE insulated cables conforming / as per IS : 1544-1, IS: 7089-1, BS: 6346, IEC: 60502, BS: 5467, BS:7846 and specific customer requirement.',
        'size': ['Single Core cables up to 1000 Sq. mm', 'Multi Core Cables up to 400 Sq. mm'],
        'designProcess': [
            ['Conductor: ', 'Solid/ stranded, circular/sector shaped, copper/ aluminium'],
            ['Insulation: ', 'PVC-GP/ HR, XLPE, LSZH, FRLS-H'],
            ['Inner Health: ', 'PVC-GP / HR/ FR / FRLS-H, LSOH'],
            ['Armour (for Armoured Cables): ', 'Galvanised steel wire / strip / tape or aluminium wire'],
            ['Outer Sheath: ', 'PVC-GP / HR/ FR / FRLS-H, LSOH']
        ],
        'crossSection': '/assets/images/LT-MV/cross_section/1.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen'],
            ['GP:' ,'General Purpose']
        ],
        'features': [
            ['Voltage rating: ', 'Low tension power cables are typically rated for voltages up to 1.1 kV.'],
            ['Conductor material: ', 'Low tension power cables are typically made of copper or aluminum, which are good conductors of electricity.'],
            ['Insulation material: ', 'Low tension power cables are insulated with materials such as PVC (Polyvinyl chloride), XLPE (Cross-linked polyethylene), which provide good electrical insulation and mechanical strength.'],
            ['Armor: ', 'Low tension power cables may be armored with materials such as steel wire, steel tape, or aluminum wire to provide protection against mechanical damage.']
        ]
    },
    {
        'image': '/assets/images/LT-MV/2.webp',
        'heading': 'LT/MV PVC/XLPE Control Cables',
        'type': 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        'background':'/assets/images/productType/power_bg.png',
        'slug': 'control-cables',
        'infoDescription': 'Control cables are electrical cables used for transmitting control signals and low-level power in various industrial and commercial applications. These cables are used to control and coordinate the operation of electrical and mechanical systems, such as motors, pumps, and conveyor systems. Control cables come in different sizes and specifications to meet the specific needs of different applications, and they can be made of various materials, including copper & aluminum materials. The insulation material used in control cables must be able to withstand the harsh conditions of the application, including temperature, chemicals, and mechanical stress. Control cables must meet specific performance and safety standards to ensure that they can provide reliable control signals and to minimize the risk of damage or failure.\n' +
            '\n' +
            '\n' +
            'When selecting control cables, it is important to consider the specific requirements of the application, including the type of signal being transmitted, the length of the cable needed, and any environmental factors that may impact the cable\'s performance.',
        'description': 'PVC / XLPE insulated cables conforming to IS:1554-1, IS:7098-1, BS:6346, IEC:60502, BS:5467, BS:7846 IEC60227, BS:6004, BS:6500, IS:694 and Customer specific requirement.',
        'size': ['Generally with Sizes 1.5 Sq.mm, 2.5 Sq.mm, 4.0 Sq.mm and up to 61 cores.'],
        'designProcess': [
            ['Conductor: ', 'Solid/ stranded, flexible, circular copper.'],
            ['Insulation: ', 'PVC-GP / HR, XLPE, FRLS-H, LSZH'],
            ['Inner Sheath: ', 'PVC-GP / HR/ FR / FRLS-H, LSZH'],
            ['Armour (for Armoured Cables): ', 'Galvanised steel wire / strip / tape.'],
            ['Outer Sheath: ', 'PVC-GP / HR/ FR / FRLS-H, LSZH']
        ],
        'crossSection': '/assets/images/LT-MV/cross_section/2.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen'],
            ['GP:' ,'General Purpose']
        ],
        'features': [
            ['Conductor material: ', 'Control cables are typically made of copper, which is a good conductor of electricity.'],
            ['Insulation material: ', 'Control cables are insulated with materials such as PVC (Polyvinyl chloride), XLPE (Cross-linked polyethylene), or FEP (Fluorinated ethylene propylene), which provide good electrical insulation and mechanical strength.'],
            ['Color coding: ', 'Control cables may be color-coded to facilitate identification and installation.'],
            ['Flexibility: ', 'Control cables are designed to be flexible, allowing them to be easily routed and installed in tight spaces.']
        ]
    },
    {
        'image': '/assets/images/LT-MV/3.webp',
        'heading': 'Instrumentation/Signal Cables',
        'type': 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        'background':'/assets/images/productType/power_bg.png',
        'slug': 'instrumental-cables',
        'infoDescription': 'Instrumentation cables are specialized electrical cables used in industrial and commercial applications for transmitting control signals, low-level power, and data. These cables are used to connect process control and monitoring instruments, such as sensors, transducers, and control systems. Instrumentation cables are designed to meet the specific requirements of different applications, including harsh environmental conditions and electromagnetic compatibility. They can be made of various materials, including copper and have insulation made of materials such as PVC & XLPE & PE. The shielding and grounding of instrumentation cables are also important factors in ensuring reliable and accurate signal transmission. Instrumentation cables must meet specific performance and safety standards to ensure that they can provide reliable signal transmission and to minimize the risk of damage or failure.\n' +
            '\n' +
            'When selecting instrumentation cables, it is important to consider the specific requirements of the application, including the type of signal being transmitted, the length of the cable needed, and any environmental factors that may impact the cable\'s performance.',
        'description': 'PVC / XLPE / PE insulated cables conforming / as per BS EN: 50288 (formerly BS:5308-I & II), IEC-60502-1, IS: 1554-I, IS:7098-I, EIL6-52- 46 and Customer specific requirement.',
        'size': ['Generally to be Manufactured with sizes 0.50 Sq.mm, 0.75Sq.mm, 1 .OSq.mm, 1.5 Sq.mm, 2.5 Sq.mm up to 50 pairs/Triad'],
        'designProcess': [
            ['Conductor: ', 'Solid/ stranded/ flexible copper, (bare / tinned)'],
            ['Insulation: ', 'PVC-GP / HR, PE, XLPE, FRLS-H LSZH.'],
            ['Shielding: ', ' Individual & overall or overall screen only by Al-mylar tape/ Copper tape/ copper wire braid'],
            ['Inner Sheath: ', 'PVC-GP / HR/ FR / FRLS-H, PE, LSZH'],
            ['Armour (for Armoured Cables): ', 'Galvanised steel wire / strip / tape/ double tape / Braid'],
            ['Outer Sheath: ', 'PVC-GP / HR/ FR / FRLS-H, LSZH']
        ],
        'crossSection': '/assets/images/LT-MV/cross_section/3.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen'],
            ['PE: ', 'Polyethylene']
        ],
        'features': [
            ['Shielding: ', 'Instrumentation cables may be shielded with materials such as aluminum foil or copper wire mesh to provide protection against electromagnetic interference (EMI) and radio frequency interference (RFI), which can degrade signal quality.'],
            ['Conductor material: ', 'Instrumentation cables are typically made of copper, which is a good conductor of electricity.'],
            ['Insulation material: ', 'Instrumentation cables are insulated with materials such as PVC (Polyvinyl chloride), XLPE (Cross-linked polyethylene), PE (Polyethelene) , or FEP (Fluorinated ethylene propylene) , which provide good electrical insulation and mechanical strength.'],
            ['Color coding: ', 'Instrumentation cables may be color-coded to facilitate identification and installation.'],
            ['Low capacitance: ', 'Instrumentation cables are designed to have low capacitance, which minimizes signal loss and distortion.'],
            ['Noise immunity: ', ' Instrumentation cables are designed to have high noise immunity, which ensures accurate signal transmission in noisy environments.']
        ]

    },
    {
        'image': '/assets/images/LT-MV/4.webp',
        'heading': 'Fire Survival Cables',
        'type': 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        'background':'/assets/images/productType/power_bg.png',
        'slug': 'low-smoke-zero-halogen-cables-lt',
        'infoDescription': "Fire survival cables are electrical cables that have been designed to maintain their functionality in the event of a fire. These cables are made with materials that are resistant to fire, smoke, and heat, allowing them to continue to function and provide power to safety systems during a fire emergency. Fire survival cables are commonly used in critical infrastructure and high-risk facilities such as airports, power plants, and hospitals. They are an important component of fire protection systems as they ensure that emergency systems, such as fire alarms, sprinklers, and lighting, remain operational even if a fire occurs. Fire survival cables must meet strict safety and performance standards to ensure that they can provide reliable performance during a fire emergency.When selecting fire survival cables, it is important to consider the specific requirements of the building or application, including the fire rating required, the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable's performance.",
        'description': 'We supply a comprehensive range of low smoke Zero Halogen (LSZH) Cables for use in applications where smoke emission and toxic fumes could pose a risk to human health and essential equipment’s in the event of fire.',
        'size': [],
        'designProcess': [],
        'features': [
            ['Resistance to high temperatures: ', 'Fire survival cables are designed to withstand high temperatures for a specific period of time, usually 30 to 120 minutes, depending on the type of cable and the fire rating.'],
            ['Circuit integrity: ', 'Fire survival cables are designed to maintain circuit integrity in the event of a fire, ensuring that critical systems, such as fire alarms and emergency lighting, continue to function.'],
            ['Low smoke and toxic gas emission: ', 'Fire survival cables are designed to emit low levels of smoke and toxic gases in the event of a fire, reducing the risk of harm to people and equipment.'],
        ],
        'crossSection': '/assets/images/LT-MV/cross_section/4.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen']
        ]
    },
    {
        'image': '/assets/images/LT-MV/5.webp',
        'heading': 'Railway Signalling Cables',
        'type': 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        'background':'/assets/images/productType/power_bg.png',
        'slug': 'railway-signaling-cables',
        'infoDescription': "Railway signalling cables are a type of electrical cable used in railway signalling systems to transmit signals between control systems, signal lights, and other equipment. These cables play a critical role in ensuring the safe and reliable operation of railways by transmitting signals that indicate the position of trains, speed limits, and other important information.When selecting railway signalling cables, it is important to consider the specific requirements of the signalling system, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable's performance.",
        'description': 'Railway signalling Cables are for supply of Signals to signal posts for indicating Red, Green, Yellow lights for smooth running of trains. The Signal posts are interconnected between two stations and are controlled from signal room at station by stationmaster controlling movement of trains.',
        'size': ['The cross section area of conductor is 1.5 & 2.5 Sq mm.', 'Number of cores: Upto 37 cores.'],
        'designProcess': [
            ['Conductor: ', 'Solid/ stranded, circular'],
            ['Insulation: ', 'PVC'],
            ['Inner Health: ', 'PVC'],
            ['Armour (for Armoured Cables): ', 'Galvanised steel wire/ GI tape/ Formed wire'],
            ['Outer Sheath: ', 'PVC']
        ],
        'crossSection': '/assets/images/LT-MV/cross_section/5.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant']
        ],
        'features': [
            ['Durability: ', 'Railway signalling cables are designed to withstand harsh environmental conditions, including extreme temperatures, moisture, and mechanical stress.'],
            ['Electromagnetic compatibility (EMC): ', 'Railway signalling cables are designed to minimize electromagnetic interference (EMI) and ensure reliable signal transmission'],
            ['Fire resistance: ', 'Railway signalling cables are often required to meet strict fire resistance standards to ensure that they can continue to function in the event of a fire.'],
            ['Compliance with regulations: ', 'Many countries have regulations and standards that require the use of specific types of cables in railway signalling systems, and it is important to choose cables that meet these requirements.']
        ]
    },
    {
        'image': '/assets/images/House/1.webp',
        'heading': 'Single Core and Multicore Flexible Cables',
        'type': 'HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES',
        'background':'/assets/images/productType/house_bg.png',
        'slug': 'single-core-flexible-wires',
        'infoDescription': 'Single-core and multicore flexible cables are types of electrical cables that are commonly used in a variety of applications, including power distribution, machinery, and automation systems.\n' +
            'Single-core flexible cables consist of a single conductor surrounded by insulation and a protective outer jacket. They are typically used in applications where the cable will be bent or twisted, such as in moving machinery or equipment.\n' +
            'Multicore flexible cables, on the other hand, contain multiple conductors within a single outer jacket. These cables are often used in applications where multiple connections are required, such as in control panels or automation systems.\n' +
            'When selecting a single-core or multicore flexible cable, it\'s important to consider the specific needs of your application, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable\'s performance.',
        'description': 'PVC-GP, HR-FR, FR-PVC, HR-PVC, FRLS-H, LSOH Insulated conflrming/as per IS:694/2010 /BS:6004/ BS:6007/ IEC:60227-3/ BS:6500/ BS:7919/ BS:7211/ BS:6231/BS:6141',
        'size': ['0.5 to 300 Sq.mm unsheathed or as per customer requirement.'],
        'designProcess': [
            ['Conductor: ', 'Solid(Class-l), Stranded (Class-2),Annealed Bare / Tinned High conductivity copper conductor confirming to IS:8130, BS:6360or IEC:60228.'],
            ['Insulation: ', 'PVC-GP/HR-FR/FR-PVC/HR-PVC/FRLS-H/LSOH'],
            ['Colour: ', 'Red, Yellow, Blue, Black, Green, Grey, Green/Yellow, White or as per customer'],
            ['Voltage: ', '300 / 500 Volts, 450/750 Volts , 1100 Volts'],
            ['Operating Temperature:  ', '70°C& 85°C']
        ],
        'features': [
            ['Flexibility: ', 'Both types of cables are designed to be flexible and easy to maneuver, allowing them to be bent and twisted without damaging the internal wiring.'],
            ['Durability: ', 'Flexible cables are often made with durable materials that can withstand abrasion, chemicals, and other harsh environmental conditions.'],
            ['Customization: ', '3.These cables can be customized to meet specific electrical requirements, including the number of conductors, the size of the conductors, and the type of insulation and outer jacket used.']
        ],
        'crossSection': '/assets/images/House/cross_section/1.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen']
        ]
    },
    {
        'image': '/assets/images/House/2.webp',
        'heading': 'Submersible Flat Cables',
        'type': 'HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES',
        'background':'/assets/images/productType/house_bg.png',
        'slug': 'pvc-insulated-submersible-flat-cables',
        'infoDescription': 'Submersible pump cables are electrical cables specifically designed for use in submersible pumps. The pumps which are submerged in water or other liquids. These cables are typically made with materials that are resistant to water, moisture, and chemicals, and are designed to be durable and long-lasting in harsh environments. \n' +
            'When selecting a submersible pump cable, it\'s important to consider the specific needs of your application, including the type of liquid being pumped, the length of the cable required, and the electrical requirements of the pump. ',
        'description': 'PVC Insulated PVC Sheath or HR-PVC Insulated HR-PVC Sheathed confirming / as per lS:694:2010 Three & Four Core Flat Cables',
        'size': ['1.0, 1.5, 2.5, 4.0, 6.0, 10, 16, 25, 35, 50, 70, 95, Sq.mm or as per customer requirement.'],
        'designProcess': [
            ['Conductor: ', 'Stranded, Flexible (Class-5 and Class-6) Annealed High conductivity copper conductor confirming to IS:8130'],
            ['Insulation: ', ' PVC/ HR-PVC/XLPE Sheath PVC/HR-PVC'],
            ['Colours', 'Generally Black or as per customer requirement'],
            ['Voltage: ', '450/750/1100 Volts'],
            ['Operating Temperature: ', '70°C & 85°C']
        ],
        'features': [
            ['Waterproofing: ', 'Submersible pump cables are designed to be waterproof, preventing water and moisture from entering the cable and causing damage to the internal wiring.'],
            ['Chemical resistance: ', 'These cables are often made with materials that are resistant to chemicals commonly found in liquids such as oil, gasoline, and sewage.'],
            ['Strength and durability: ', 'Submersible pump cables are built to withstand the harsh conditions of submersion in liquids, and are typically made with strong, durable materials that can resist abrasion and wear.'],
            ['Flexibility: ', 'These cables may need to be bent or twisted to fit into tight spaces within the pump or pump control panel, so they are often designed to be flexible and easy to maneuver.']
        ],
        'crossSection': '/assets/images/House/cross_section/2.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen']
        ]
    },
    {
        'image': '/assets/images/House/3.webp',
        'heading': 'LSZH Cables',
        'type': 'HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES',
        'background':'/assets/images/productType/house_bg.png',
        'slug': 'low-smoke-zero-halogen-cables-house',
        'infoDescription': 'Halogen-free cables, also known as low-smoke, zero-halogen (LSZH) cables, are electrical cables that are designed to minimize the amount of smoke, toxic gases, and corrosive substances that are released in the event of a fire.\n' +
            'Traditional cables can contain halogenated materials, such as PVC insulation and jackets, which can release harmful gases and smoke when exposed to high heat or fire. In contrast, halogen-free cables are made with insulation and jackets that are free from halogenated materials and are designed to release less smoke and fewer toxic fumes when exposed to high heat or fire.\n' +
            'When selecting an enamelled wire, it\'s important to consider the specific needs of your application, including the temperature and electrical requirements, the size of the wire needed, and any environmental factors that may impact the wire\'s performance.',
        'description': 'We supply a comprehensive range of low smoke Zero Halogen (LSZH) Cables for use in applications where smoke emission and toxic fumes could pose a risk to human health and essential equipment’s in the event of fire.',
        'size': [],
        'designProcess': [],
        'features': [
            ['Safety: ', 'Halogen-free cables are designed to reduce the amount of smoke, toxic gases, and corrosive substances released during a fire, which can help protect people and property.'],
            ['Durability: ', 'Halogen-free cables are often made with materials that are resistant to heat, chemicals, and environmental factors, making them durable and long-lasting.'],
            ['Environmental friendliness: ', 'Halogen-free cables are better for the environment because they do not contain halogenated materials, which can be harmful to the environment during production and disposal.'],
            ['Compliance with regulations: ', 'Many countries and industries have regulations that require the use of halogen-free cables in certain applications, such as public transportation and building construction.']
        ],
        'crossSection': '/assets/images/House/cross_section/3.png',
        'abbreviations': [
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen']
        ]
    },
    {
        'image': '/assets/images/House/4.webp',
        'heading': ' Photovoltaic (PV) Solar Cables',
        'type': 'HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES',
        'background':'/assets/images/productType/house_bg.png',
        'slug': 'solar-pv-dc-cables',
        'infoDescription': '\n' +
            'PV solar cables, also known as solar photovoltaic (PV) cables, are electrical cables designed specifically for use in solar power systems. These cables are used to transmit DC power generated by solar panels to inverters and other equipment that converts the power into AC power for use in homes and businesses.\n' +
            '\n' +
            'When selecting PV solar cables, it\'s important to consider the specific requirements of the solar power system, including the voltage and current requirements, the length of the cable needed, and any environmental factors that may impact the cable\'s performance',
        'description': 'DC Solar cable are single core XLPO  copper cables, these are insulated and sheathed with cross linkable LSZH which has UV as well as ozone protection properties (generally conforming to BS EN 50618:2014).',
        'size': ['1.0, 1.5, 2.5, 4.0, 6.0, 10, 16, 25, 35, 50, 70, 95, Sq.mm or as per customer requirement.'],
        'designProcess': [
            ['Conductor: ', ' ATC    CL-5   IEC 60228'],
            ['Insulation: ', 'Cross linked insulation halogen free Polyolefin'],
            ['Sheathing:', 'Crosslinks halogen free Polyolefin sheathing']
        ],
        'features': [
            ['UV resistance: ', ' PV solar cables are designed to withstand prolonged exposure to sunlight and have UV-resistant insulation to prevent degradation over time.'],
            ['Weather resistance: ', ' PV solar cables are often designed to withstand a range of environmental conditions, including extreme temperatures, moisture, and harsh weather conditions.'],
            ['Low smoke and toxicity: ', 'PV solar cables are often made with materials that are low-smoke and low-toxicity, which can help protect people and property in the event of a fire.'],
            ['Compliance with standards: ', 'PV solar cables must meet specific industry standards to ensure they are safe and reliable for use in solar power systems.']
        ],
        'crossSection': '/assets/images/House/cross_section/4.png',
        'abbreviations': [
            ['XLPO', 'Cross linked Polyolefin'],
            ['PVC: ', 'Polyvinyl Chloride'],
            ['XLPE: ', 'Cross Linked Polyethylene'],
            ['HR: ', 'Heat resistant'],
            ['FR: ', 'Flame Retardant'],
            ['FRLS: ', 'Flame Retardant Low Smoke'],
            ['LSZH: ', 'Low Smoke Zero Halogen']
        ]
    },
    {
        'image': '/assets/images/Winding/1.webp',
        'heading': 'Enamelled Wires',
        'type': 'SUPER ENAMELLED WINDING WIRES',
        'slug': 'enameled-wires',
        'background':'/assets/images/productType/winding_bg.png',
        'infoDescription': 'Enamelled wires, also known as magnet wires or winding wires, are electrical conductors that are coated with a thin layer of insulation made from enamel or varnish. These wires are commonly used in a variety of applications, including motors, transformers, generators, and other electrical equipment.\n' +
            'The insulation on enamelled wires serves to protect the wire from electrical current and prevent it from shorting out. The thin layer of enamel or varnish also provides a high level of electrical insulation, which allows the wire to be used in applications where the wire is in close proximity to other conductors.\n' +
            'When selecting an enamelled wire, it\'s important to consider the specific needs of your application, including the temperature and electrical requirements, the size of the wire needed, and any environmental factors that may impact the wire\'s performance.',
        'description': 'We have range of 8 SWG to 40 SWG in copper Conductor and 8 SWG to 30 SWG in Almunium Conductor. Super Enamelled Copper & Almunium wires are used in domestic appliances, winding of electric motors, transformers, inductors, generators, loudspeaker coils, electromagnets, relay coils etc.',
        'size': [],
        'designProcess': [],
        'features': [
            ['High electrical conductivity: ', 'Enamelled wires are made from high-quality copper or aluminum, which provides excellent electrical conductivity.'],
            ['Thermal resistance: ', 'Enamelled wires are designed to withstand high temperatures, making them ideal for use in applications where heat is a factor.'],
            ['Corrosion resistance: ', 'The insulation on enamelled wires protects the wire from corrosion and other environmental factors.'],
            ['Customization: ', 'Enamelled wires can be customized to meet specific requirements, including the size of the wire, the type of insulation used, and the level of electrical conductivity needed.']
        ],
        'crossSection': '/assets/images/Winding/cross_section/1.webp',
        'abbreviations': [
        ],
        'products':[
            {
                'productName':'Polyester Enamelled Round Copper Wire',
                'thermalClass':'130 (B)',
                'specificationCopper':'IS 13730/ IEC 60317 PART-34',
                'specificationAluminium':'IS 13730/IEC 60317 PART-9',
                'rangeCopperAluminium':['8 SWG to 40 SWG','8 SWG to 30 SWG'],
                'insulationType':'Grade - 1,2 & 3',
                'heatShock':'155/155',
                'cutThrough':'240',
                'flexibility':'Very Good',
                'application':'Domestic Appliances, Winding of electric motors, Transformers, Inductors, Generators, loudspeaker coils, electromagnets, Relay Coils, etc',
                'packing':['PT-4 to PT-90', 'As per Customer Requirement']
            },
            {
                'productName':'Modified Polyster Enamelled Round Copper Wire',
                'thermalClass':'155 (F)',
                'specificationCopper':'IS 13730/ IEC 60317 PART-03',
                'specificationAluminium':'',
                'rangeCopperAluminium':['8 SWG to 40 SWG','8 SWG to 30 SWG'],
                'insulationType':'Grade - 1,2 & 3',
                'heatShock':'175/---',
                'cutThrough':'240',
                'flexibility':'Good',
                'application':'Domestic Appliances, Winding of electric motors, Transformers, Inductors, Generators, loudspeaker coils, electromagnets, Relay Coils, etc',
                'packing':['PT-4 to PT-90', 'As per Customer Requirement']
            },
            {
                'productName':'Polysesterimide Enamelled Round Copper Wire',
                'thermalClass':'180 (H)',
                'specificationCopper':'IS 13730/ IEC 60317 PART-08',
                'specificationAluminium':'IS 13730/ IEC 60317 PART-15',
                'rangeCopperAluminium':['8 SWG to 40 SWG','8 SWG to 30 SWG'],
                'insulationType':'Grade - 1,2 & 3',
                'heatShock':'200/200',
                'cutThrough':'300',
                'flexibility':'Good',
                'application':'Auto Mobile Industry, BLDC FANS, electromagnets, AC and Referigerator Compressor, Relay Coils, etc.',
                'packing':['PT-4 to PT-90', 'As per Customer Requirement']
            },
            {
                'productName':'Polysesterimide over coated with Polyanideimide Enamelled Round CopperWire',
                'thermalClass':'200 (DC)',
                'specificationCopper':'IS 13730/ IEC 60317',
                'specificationAluminium':'IEC 60317 PART-25',
                'rangeCopperAluminium':['8 SWG to 40 SWG','8 SWG to 30 SWG'],
                'insulationType':'Grade - 1,2 & 3',
                'heatShock':'220/240',
                'cutThrough':'320',
                'flexibility':'Very Good',
                'application':'Auto Mobile Industry, BLDC FANS, electromagnets, Relay Coils, AC and Referigerator Compressor, Wind generators, etc.',
                'packing':['PT-4 to PT-90', 'As per Customer Requirement']
            }
        ]
    }
]

/*
 * Class
 */
class PortfolioDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {
        const {match} = this.props
        const slug = match.params.slug;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Product'/>

                {/* Start Header Area  */}
                <HeaderThree homeLink="/" logo="symbol-light" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                {
                    _List.filter(x => x.slug === slug).map(x => (
                        <>
                            <div className="rn-page-title-area pt--120 pb--190"
                                    style={{'backgroundImage':`url(${x.background})`,'backgroundSize':'cover','backgroundPosition':'center'}} data-black-overlay="7">
                                <div className="container" style={{'width': '100%'}}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="rn-page-title text-center pt--100">
                                                <h2 className="title theme-gradient">{x.type}</h2>
                                                <p>{x.heading}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Breadcrump Area */}

                            {/* Start Portfolio Details */}

                            <div className="rn-portfolio-details pt--60 pb-50 bg_color--1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="portfolio-details">
                                                <div className="inner">
                                                    <h2>{x.heading}</h2>
                                                    <p className="subtitle">{x.infoDescription}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* End Portfolio Details */}

                            {/* Start Gallery Details */}
                            <div className="rn-team-wrapper pb--80 bg_color--1" id="team">
                                <div className="rn-team-area">
                                    <div className="container">
                                        <TeamOne product={x.image}
                                                    cross={x.crossSection}
                                                    column="col-lg-5 col-md-6 col-sm-6 col-12 d-flex justify-content-center"
                                                    teamStyle="team-style--bottom" item="4"/>
                                    </div>
                                </div>
                            </div>
                            {/* End Gallery Details */}

                            <div className='row mt-100 mb-5'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <TabOne style={{ fontSize: '26px' }} description={x.description} size={x.size}
                                                    designProcess={x.designProcess} tabStyle="tab-style--1"
                                                    features={x.features} abbreviations={x.abbreviations}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                x.slug === 'enameled-wires' ? <TabEnamelled products={x.products} tabStyle="tab-style--1"
                                                                            /> : ''
                            }
                        </>
                    ))
                }


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>


            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default PortfolioDetails;
