/*
 * IMPORTS
 */
import React from 'react'
import ScrollToTop from 'react-scroll-up'
import {FiCheck, FiChevronUp} from "react-icons/fi"
import {Link} from 'react-router-dom'


/*
 * COMPONENTS
 */
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Helmet from "../component/common/Helmet";



/*
 * Const assignment
 */
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Innovative Cable Solutions',
        title: 'Cable <span>Connections</span> That Lasts.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

/*
 * OBJECT
 */
const PortfolioLanding = () => {
 
    // Let and Const Assignment
    const PostList = [
        {
            images: '01',
            title: 'Cables for the Oil and Gas Industry',
            category: 'Oil & Gas',
            image: '/assets/images/focus/oil.webp'
        },
        {
            images: '02',
            title: 'Cables for the Steel and Power Industry',
            category: 'Steel and Power',
            image: '/assets/images/focus/steel.png'
        },
        {
            images: '03',
            title: 'Cables for the Auto Industry',
            category: 'Automotive',
            image:'/assets/images/focus/automative.webp'
        },
        {
            images:'04',
            title:'Cables for the Railways',
            category: 'Railways',
            image: '/assets/images/focus/rail.png'
        },
        {
            images:'05',
            title:'Cables for the Appliances',
            category: 'Appliances',
            image: '/assets/images/focus/appliance.png'
        },
        {
            images:'06',
            title:'Cables for the Agriculture',
            category: 'Agriculture',
            image: '/assets/images/focus/factory.png'
        },
        {
            images:'07',
            title:'Cables for the Telecom',
            category: 'Telecom',
            image: '/assets/images/focus/tele.png'
        },
        {
            images:'08',
            title:'Cables for the Renewable Energy',
            category: 'Renewable Energy',
            image: '/assets/images/focus/renewable.png'
        }
    ];
    const namesItemOne = [
        'BMI is a leading manufacturer of Electrical Cables and Wires, having corporate office in New Delhi and manufacturing unit in Bhiwadi, Rajasthan and is group company of ESS ELL cable group.',
        'BMI is ISO 9001:2015 certified company, with advanced production lines and quality control facilities. Our integrated plant produces high-quality cables meeting global standards. We also provide customized cables to meet specific customer requirements.',
        "The quality control program of BMI Cables includes a well-equipped Testing Laboratory to test incoming and finished products at every stage. Our products have passed rigorous in-house and third-party testing.",
        'BMI is one of the leading manufacturers of specialty cables and trusted by industries such as Oil & Gas, Steel & Power, Automotive, Telecome, Agriculture and Home Appliances.',
    ];

    // Return
    return (
        <div>
            <Helmet pageTitle="BMI Cables"/>
            <HeaderThree home homeLink="/" logo="symbol-light" color="color-black"/>
            {/* Start Slider Area   */}
            <div>
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div
                            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
                            key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span style={{"height": '20px'}}></span> : ''}
                                            {value.title ? <h1 className="title"
                                                            dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ?
                                                <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a
                                                className="rn-button-style--2 btn-primary-color"
                                                href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='hexagonal__wrapper'>
                                <div className='hexagonal one'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/1.webp)'}}/>
                                </div>
                                <div className='hexagonal two'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/2.webp)'}}/>
                                </div>
                                <div className='hexagonal three'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/16.webp)'}}/>
                                </div>
                                <div className='hexagonal four'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/3.webp)'}}/>
                                </div>
                                <div className='hexagonal five'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/15.webp)'}}/>
                                </div>
                                <div className='hexagonal six'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/4.webp)'}}/>
                                </div>
                                <div className='hexagonal seven'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/17.webp)'}}/>
                                </div>
                                <div className='hexagonal eight'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/5.webp)'}}/>
                                </div>
                                <div className='hexagonal nine'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/6.webp)'}}/>
                                </div>
                                <div className='hexagonal ten'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/7.webp)'}}/>
                                </div>
                                <div className='hexagonal eleven'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/8.webp)'}}/>
                                </div>
                                <div className='hexagonal twelve'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/18.webp)'}}/>
                                </div>
                                <div className='hexagonal thirteen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/9.webp)'}}/>
                                </div>
                                <div className='hexagonal fourteen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/10.webp)'}}/>
                                </div>
                                <div className='hexagonal fifteen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/14.webp)'}}/>
                                </div>
                                <div className='hexagonal sixteen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/11.webp)'}}/>
                                </div>
                                <div className='hexagonal seventeen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/12.webp)'}}/>
                                </div>
                                <div className='hexagonal eighteen'>
                                    <div style={{'background': 'url(/assets/images/homepage-images/13.webp)'}}/>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Us Area */}
            <div id="aboutUs" className="fix">
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.webp"
                                             alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner text-center">
                                        <div className="section-title">
                                            <h2 className="title">About Us</h2>
                                        </div>
                                        <div className="row mt--30">
                                            <div>
                                                <ul className="list-style--1">
                                                    {namesItemOne.map((name, index) => {
                                                        return <li key={index}><FiCheck/> {name}</li>;
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="view-more-btn mt--60 mt_sm--30 text-center d-flex justify-content-center">
                                                    <Link className="rn-button-style--2 btn-solid" to="/about"><span>More About Us</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Us Area */}

            {/* Start Portfolio Area */}
            <div id="products" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Product category</h2>
                                        <p>Experience seamless connectivity and uninterrupted power with our high-quality cables.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <PortfolioList styevariation="text-center mt--40"
                                            column="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-center"
                                            item="6"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}


            {/*/!* Start Blog Area *!/*/}
            <div id="focus" className="fix">
                <div className="rn-blog-area pt--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center justify-content-center">
                                    <h2>Sectors</h2>
                                    <p>Power is paramount importance to life and to transmit and distribute it in today's world, we need state-of-the-art cables. BMI has been trying to address the growing need for quality cables and has successfully supplied cable solutions to various sectors.</p>
                                </div>
                            </div>
                            {/*<div className="col-lg-6 col-md-12 col-sm-12 col-12">*/}
                            {/*    <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">*/}
                            {/*        <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">50+ Happy Customers</span></a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row mt--60 mt_sm--40 mb--80 justify-content-center">
                            {PostList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <img className="w-100" src={value.image}
                                                alt="Blog Images"/>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title">{value.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/*/!* End Blog Area *!/*/}

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            <Footer/>
        </div>
    )
}

/*
 * EXPORTS
 */
export default PortfolioLanding;
