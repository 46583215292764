/*
 * IMPORTS
 */
import React, { Component } from "react";
import {Link} from 'react-router-dom'


/*
 * Class Component
 */
class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.svg" alt="Logo Images"/>
                    </li>
                </ul>
                <div className='d-flex justify-content-center p-5'>
                <Link to='/customer'><button className='rn-button-style--2 btn-solid'>More Customers</button></Link>
                </div>
            </React.Fragment>
        )
    }
}
/*
 * EXPORTS
 */
export default BrandTwo;
