/*
 * IMPORT
 */
import React, { Component } from "react";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";


/*
 * COMPONENT
 */
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import ContactTwo from "../elements/contact/ContactTwo";
import PageHelmet from "../component/common/Helmet";

/*
 * Class
 */
class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <HeaderThree contact homeLink="/" logo="symbol-light" color="color-black"/>

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 m-auto">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact Us</h2>
                                    <p className='welcomeDescription'> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact With Phone Number</h4>
                                        <p><a href="tel:+91-11-46032371">+91-11-46032371</a></p>
                                        <p><a href="tel:+91-11-46032372">+91-11-46032372</a></p>
                                        <p style={{'opacity':'0'}}>+91-11-46032372</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a rel="noopener noreferrer" href="mailto:admin@gmail.com">sales@bmicables.com</a></p>
                                        <p><a rel="noopener noreferrer" href="mailto:example@gmail.com">marketing@bmicables.com</a></p>
                                        <p style={{'opacity':'0'}}>+91-11-46032372</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <a rel="noopener noreferrer" href="https://www.google.com/maps?q=28.5691601,77.1870646&z=17&hl=en" target="_blank">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    </a>
                                    <div className="inner">
                                        <h4 className="title">Corporate Office</h4>
                                        <p>BMI Cables Private Limited
                                            406, Bhikaji Cama Bhawan,
                                            Bhikaji Cama Place,
                                            New Delhi-110066</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <a rel="noopener noreferrer" href="https://www.google.com/maps?q=28.1171646,76.7874624&z=17&hl=en" target="_blank">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    </a>
                                    <div className="inner">
                                        <h4 className="title">Factory</h4>
                                        <p>BMI Cables Private Limited
F- 81 to 84, Kushkhera, RIICO Industrial Area, Bhiwadi,
Distt. Alwar, Rajasthan-301019</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End contact Top Area  */}

                {/* Start contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default Contact
