/*
 * IMPORTS
 */
import React, {Component} from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {FiCheck} from "react-icons/fi";


/*
 * Class
 */
class TabsEnamelled extends Component {
    render() {
        const {tabStyle, products} = this.props;

        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area p-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <p className='font-weight-light font--28 ptb--20'>Our Range of Products</p>
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        {
                                            products.map(x => (
                                                <Tab>{x.productName}</Tab>
                                            ))
                                        }

                                    </TabList>

                                        {
                                            products.map((x, index) => (
                                                <TabPanel>
                                                    <div key={index} className="single-tab-content">
                                                        <div className="mt--30">
                                                            <ul className="list-style--1">
                                                                <li><FiCheck/> Thermal Class : {x.thermalClass}</li>
                                                                <li><FiCheck/> Specification Copper
                                                                    : {x.specificationCopper}</li>
                                                                <li><FiCheck/> Specification Aluminum
                                                                    : {x.specificationAluminium}</li>
                                                                <li><FiCheck/> Range Copper / Aluminum
                                                                    : {x.rangeCopperAluminium[0] / x.rangeCopperAluminium[1]}
                                                                </li>
                                                                <li><FiCheck/> Insulation Type Copper Aluminum
                                                                    : {x.insulationType}</li>
                                                                <li><FiCheck/> Heat shock (°C) - Cu / Al : {x.heatShock}
                                                                </li>
                                                                <li><FiCheck/> Cut Through (°C) : {x.cutThrough}</li>
                                                                <li><FiCheck/> Flexibility & Adherence : {x.flexibility}
                                                                </li>
                                                                <li><FiCheck/> Application : {x.application}</li>
                                                                <li><FiCheck/> Packing
                                                                    : {x.packing[0]} or {x.packing[1]}</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                    ))
                                        }

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}

/*
 * EXPORT
 */
export default TabsEnamelled;
