/*
 * IMPORTS
 */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


/*
 * COMPONENTS
 */
import Business from './home/Business';
import PortfolioLanding from './home/PortfolioLanding';
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import error404 from "./elements/error404";
import Portfolio from "./blocks/Portfolio";
import PageScrollTop from './component/PageScrollTop';
import Customer from "./home/Customer";


/*
 * Class Component
 */
class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={PortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/details/:slug`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/:slug`} component={Portfolio}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/customer`} component={Customer} />
                        <Route component={error404}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
