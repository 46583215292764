/*
 * IMPORTS
 */
import React ,{ Component }from "react";
import { MdHighQuality, MdVerifiedUser } from "react-icons/md";
import { GiStarsStack } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import {GiWallet} from "react-icons/gi";
import {FaShippingFast} from 'react-icons/fa' 

/*
 * Const Assignment
 */
const ServiceList = [
    {
        icon: <MdHighQuality />,
        title: 'EXTENSIVE PRODUCT RANGE',
        description: 'We have extensive product range of  LV/MV, PVC/ XLPE Power and Control Cables, LV/MV Elastomeric (Rubber) Cables , Instrumentation Cables, Automotive Cables, Enamelled Copper Wires, Submersible Wires, HFFR/LSZH Cables, UG/OCP Mining Cables and wind turbine cables.'
    },
    {
        icon: <GiStarsStack />,
        title: 'QUALITY',
        description: 'Customers will choose a product that meets their  expectation in terms of material quality, durability, functionality, safety and so on. At BMI we offer a high quality product that ensures customer satisfaction  inbuild brand credibility.'
    },
    {
        icon: <FaHandshake />,
        title: 'RELIABLITY',
        description: 'At BMI our customers feel confident in their investment, knowing that they are getting a product that is durable, safe and consistent with their requirements. It builds trust and loyalty between our customers and the business.'
    },
    {
        icon: <MdVerifiedUser />,
        title: 'CUSTOMER SERVICE',
        description: 'Customer Service in a crucial aspect of any successful business. At BMI our priority is always to work towards customer satisfaction and for the same we have a dedicated customer service team which provide us a differentiated experience. '
    },
    {
        icon: <GiWallet />,
        title: 'AFFORDABILITY',
        description: 'Affordability is critical aspect that customers consider when purchasing products. At BMI we emphasize on a competitive pricing of our products and also offer a good value to customers at a reasonable price.'
    },
    {
        icon: <FaShippingFast />,
        title: 'DELIVERY',
        description: 'Today Customers value in time delivery of their orders and BMI offers its customers fast and efficient service, comprehensive  coverage, safe and secure shipping.'
    }
]

/*
 * Class Component
 */
class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row justify-content-center">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default ServiceThree;
