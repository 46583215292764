let data = [
    {
        images: '1',
        title: 'V.K. GUPTA',
        designation: 'VP MARKETING',
    },
    {
        images: '2',
        title: 'SURESH BAUNTHIYAL',
        designation: 'GENERAL MANAGER (MARKETING & SALES)',
    },
    {
        images: '3',
        title: 'MANOJ SHARMA',
        designation: 'AGM BUSINESS DEVELOPMENT'
    },
    {
        images: '4',
        title: 'VIRENDER PANDITA',
        designation: 'SR. MANAGER BUSINESS DEVELOPMENT'
    }

];

export default data;
