/*
 * IMPORTS
 */
import React from 'react'

/*
 * OBJECT
 */
const TeamOne = (props) => {
    return (
        <div className="row d-flex justify-content-center ">
            <div className={`${props.column}`}>
                <div className={`team ${props.teamStyle}`}>
                    <div>
                        <img src={`${props.product}`} alt="wires"/>
                    </div>
                </div>
            </div>
            <div className={`${props.column}`}>
                <div className={`team ${props.teamStyle}`}>
                    <div>
                        <img src={props.cross} alt="wires"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
/*
 * EXPORTS
 */
export default TeamOne
