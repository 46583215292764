/*
 * IMPORTS
 */
import React, {Component} from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {FiCheck} from "react-icons/fi";


/*
 * Class
 */
class TabsOne extends Component {
    render() {
        let
            tab1 = "Description",
            tab2 = "Design",
            tab3 = "Sizes",
            tab4 = "Features",
            tab5 = "Abbreviations";
        const {tabStyle, size, designProcess, features, description, abbreviations} = this.props;
    

        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        {description.length === 0 ? '' : <Tab>{tab1}</Tab>}
                                        {designProcess.length === 0 ? '' : <Tab>{tab2}</Tab>}
                                        {size.length === 0 ? '' : <Tab>{tab3}</Tab>}
                                        {features.length === 0 ? '' : <Tab>{tab4}</Tab>}
                                        {abbreviations.length === 0 ? '' : <Tab>{tab5}</Tab>}
                                    </TabList>
                                    {
                                        description.length === 0 ? '' : <TabPanel>
                                            <div className="single-tab-content">
                                                <p className='font-weight-normal' style={{'color':'#000'}}>{description}</p>
                                            </div>
                                        </TabPanel>
                                    }

                                    {
                                        designProcess.length === 0 ? '' :
                                            <TabPanel>
                                                <div className="single-tab-content">
                                                    <div className="mt--30">
                                                        <ul className="list-style--1">
                                                            {designProcess.map((name, index) => {
                                                                return <li className='font-weight-normal' style={{'color':'#000'}} key={index}><FiCheck/> {name}</li>;
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                    }

                                    {
                                        size.length === 0 ? '' : <TabPanel>
                                            <div className="single-tab-content">
                                                <div className="mt--30">
                                                    <ul className="list-style--1">
                                                        {size.map((name, index) => {
                                                            return <li className='font-weight-normal' style={{'color':'#000'}} key={index}><FiCheck/> {name}</li>;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    }

                                    {
                                        features.length === 0 ? '' : <TabPanel>
                                            <div className="single-tab-content">
                                                <div className="mt--30">
                                                    <ul className="list-style--1">
                                                        {features.map((name, index) => {
                                                            return <li className='font-weight-normal' style={{'color':'#000'}} key={index}><FiCheck/> {name}</li>;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    }
                                    {
                                        abbreviations.length === 0 ? '' : <TabPanel>
                                            <div className="single-tab-content">
                                                <div className="mt--30">
                                                    <ul className="list-style--1">
                                                        {abbreviations.map((name, index) => {
                                                            return <li className='font-weight-normal' style={{'color':'#000'}} key={index}><FiCheck/> {name}</li>;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    }


                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}

/*
 * EXPORT
 */
export default TabsOne;
