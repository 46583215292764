/*
 * IMPORTS
 */
import React, {Component} from "react";
import {Link} from "react-router-dom";

/*
 * Const Assignment
 */
const _List = [
    {
        type: 'ELASTOMERIC (RUBBER) CABLE',
        image: '/assets/images/productType/Elastomeric.png',
        slug: 'elastomeric-cables'
    },
    {
        type: 'POWER, CONTROL AND INSTRUMENTATION CABLES',
        image: '/assets/images/productType/Power.png',
        slug: 'power-control-instrumentation-cables'
    },
    {
        type: 'HOUSE/INDUSTRIAL WIRING AND FLEXIBLE CABLES',
        image: '/assets/images/productType/House.png',
        slug: 'house-industrial-wiring-and-flexible-cables'
    },
    {
        type: 'SUPPER ENAMELLED WINDING WIRES',
        image: '/assets/images/productType/Winding.png',
        slug: 'supper-enamelled-winding-wires'
    }
]

/*
 * Class Component
 */
class PortfolioList extends Component {
    render() {
        const {column, styevariation} = this.props;
        const list = _List.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <>
                        <div className={`${column} d-flex flex-column align-items-center`} key={index}>
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail`} style={{'backgroundImage': `url(${value.image})`}}/>
                                    <div className={`bg-blr-image`} style={{'backgroundImage': `url(${value.image})`}}/>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <h4 className='text-light'>{value.type}</h4>
                                        <div className="portfolio-button">
                                            {
                                                value.slug !== 'supper-enamelled-winding-wires' ?<Link className="rn-btn" to={`/product/${value.slug}`}>View Details</Link> : <Link className="rn-btn" to={`/product/details/enameled-wires`}>View Details</Link>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-4 font--18 text-center'>{value.type}</div>
                        </div>
                    </>
                ))}

            </React.Fragment>
        )
    }
}

/*
 * EXPORTS
 */
export default PortfolioList;
