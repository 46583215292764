/*
 * IMPORTS
 */
import { useEffect } from "react";
import { withRouter } from 'react-router-dom';

/*
 * OBJECT
 */
const PageScrollTop = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return props.children;
};

/*
 * EXPORTS
 */
export default withRouter(PageScrollTop);
